@tailwind base;
@tailwind components;
@tailwind utilities;

/* fonts */
@font-face {
  font-family: "SharpGroteskBook";
  src: local('SharpGroteskBook'), url("./assets/fonts/SharpGroteskBook20.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "SharpGroteskBook";
  src: local('SharpGroteskBook'), url("./assets/fonts/SharpGroteskMedium23.ttf") format("truetype");
  font-style: medium;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "SharpGroteskBook";
  src: local('SharpGroteskBook'), url("./assets/fonts/sharpgrotesksmbold20.otf") format("truetype");
  font-style: bold;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "SharpGroteskBook";
  src: local('SharpGroteskBook'), url("./assets/fonts/SharpGroteskSmBold23.ttf") format("truetype");
  font-style: bold;
  font-weight: 900;
  font-display: swap;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: SharpGroteskBook, sans-serif !important;
  font-size: 16px;
  line-height: 16px;
}

* {
  box-sizing: border-box;
  font-size: 16px;
}

.cool-link {
  display: inline-block;
  height: 5rem;
  color: #000;
  text-decoration: none;
  align-items: center;
}

.blog-link {
  display: inline-block;
  color: #80BB42;
  text-decoration: none;
  cursor: pointer;
}

.blog-link a {
  text-decoration: none;
}

.blog-link a:hover {
  color: #1B512D;
  /* font-weight: bold; */
}

.cool-link:hover {
  border-bottom: 2px solid #1B512D;
}

.cool-link a {
  display: flex;
  height: 5rem;
  justify-content: center;
  align-items: center;
  color: #80BB42;
}

.cool-link a:hover {
  /* font-weight: 700 !important; */
  color: #1B512D !important;
}

.full-width {
  width: 100% !important;
}

.active-link {
  border-bottom: 2px solid #1B512D;
}

.active-link a {
  font-weight: 700 !important;
  color: #1B512D !important;
}

.carouselSlide {
  min-height: 70vh;
}

button:focus {
  outline: 0;
}

@media (max-width: 480px) {
  .active-link {
    border: none;
  }

  .cool-link:hover {
    border-bottom: 2px solid transparent;
  }
}

.mailchimp>form {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
}

.mailchimp>form>input {
  background: transparent !important;
  outline: none !important;
  color: rgba(222, 244, 198, 1) !important;
  font-size: 16px !important;
  font-style: normal !important;
  width: 80% !important;
}

.mailchimp>form>button {
  position: relative;
}

.mailchimp>form>button::after {
  content: url('./assets/images/submit-arrow.svg');
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #80BB42;
}

.mailchimp>form>input::-webkit-input-placeholder {
  /* Edge */
  color: rgba(222, 244, 198, 1) !important;
}

.mailchimp>form>input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(222, 244, 198, 1) !important;
}

.mailchimp>form>input::placeholder {
  color: rgba(222, 244, 198, 1) !important;
}

.mailchimp>form>input[type="email"]:focus {
  background: transparent !important;
  outline: none !important;
}